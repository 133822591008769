<template>
  <a-row :gutter="24" type="flex">
    <a-col :span="24" class="mb-24">
      <a-card
        :bordered="false"
        class="header-solid h-full"
        :bodyStyle="{ padding: 0 }"
      >
        <template #title>
          <a-row type="flex" align="middle">
            <a-col :span="24" :md="12" class="top-left-header">
              <h5 class="font-semibold m-0">
                Flow List
                <a-spin v-if="confirmLoading" />
              </h5>
            </a-col>
            <a-col :span="24" :md="12" class="top-right-header">
              <a-button>
                <router-link to="/new-flow-store">Add New Flow</router-link>
              </a-button>
            </a-col>
          </a-row>
        </template>
        <a-table
          :columns="columnData"
          :data-source="flowList"
          :pagination="false"
        >
          <template slot="updateBtn" slot-scope="row">
            <div>
              <a-button type="link" :data-id="row.key">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  @click="updateFlow(row.details.id)"
                >
                  <path
                    class="fill-gray-7"
                    d="M13.5858 3.58579C14.3668 2.80474 15.6332 2.80474 16.4142 3.58579C17.1953 4.36683 17.1953 5.63316 16.4142 6.41421L15.6213 7.20711L12.7929 4.37868L13.5858 3.58579Z"
                  />
                  <path
                    class="fill-gray-7"
                    d="M11.3787 5.79289L3 14.1716V17H5.82842L14.2071 8.62132L11.3787 5.79289Z"
                  />
                </svg>
              </a-button>
              <a-button type="link" :data-id="row.key">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  @click="cloneItem(row.details.id)"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    <path
                      d="M14 16.75H6C5.27065 16.75 4.57118 16.4603 4.05546 15.9445C3.53973 15.4288 3.25 14.7293 3.25 14V6C3.25 5.27065 3.53973 4.57118 4.05546 4.05546C4.57118 3.53973 5.27065 3.25 6 3.25H14C14.7293 3.25 15.4288 3.53973 15.9445 4.05546C16.4603 4.57118 16.75 5.27065 16.75 6V14C16.75 14.7293 16.4603 15.4288 15.9445 15.9445C15.4288 16.4603 14.7293 16.75 14 16.75ZM6 4.75C5.66848 4.75 5.35054 4.8817 5.11612 5.11612C4.8817 5.35054 4.75 5.66848 4.75 6V14C4.75 14.3315 4.8817 14.6495 5.11612 14.8839C5.35054 15.1183 5.66848 15.25 6 15.25H14C14.3315 15.25 14.6495 15.1183 14.8839 14.8839C15.1183 14.6495 15.25 14.3315 15.25 14V6C15.25 5.66848 15.1183 5.35054 14.8839 5.11612C14.6495 4.8817 14.3315 4.75 14 4.75H6Z"
                      fill="#000000"
                    ></path>
                    <path
                      d="M18 20.75H10C9.27065 20.75 8.57118 20.4603 8.05546 19.9445C7.53973 19.4288 7.25 18.7293 7.25 18V16H8.75V18C8.75 18.3315 8.8817 18.6495 9.11612 18.8839C9.35054 19.1183 9.66848 19.25 10 19.25H18C18.3315 19.25 18.6495 19.1183 18.8839 18.8839C19.1183 18.6495 19.25 18.3315 19.25 18V10C19.25 9.66848 19.1183 9.35054 18.8839 9.11612C18.6495 8.8817 18.3315 8.75 18 8.75H16V7.25H18C18.7293 7.25 19.4288 7.53973 19.9445 8.05546C20.4603 8.57118 20.75 9.27065 20.75 10V18C20.75 18.7293 20.4603 19.4288 19.9445 19.9445C19.4288 20.4603 18.7293 20.75 18 20.75Z"
                      fill="#000000"
                    ></path>
                  </g>
                </svg>
              </a-button>
            </div>
          </template>
        </a-table>

        <div class="pagination">
          <a-pagination
            v-model:current="currentPage"
            :total="pagination"
            :page-size="10"
            :hideOnSinglePage="true"
            @change="onPageChange"
          />
        </div>
      </a-card>
    </a-col>
  </a-row>
</template>

<script>
import { sendRequest } from "@/http/axios.method";
import { message } from "ant-design-vue";
import Vue from "vue";
import Swal from "sweetalert2";

const COLUMN_DATA = [
  {
    title: "ID",
    dataIndex: "id",
    scopedSlots: { customRender: "id" },
    width: 90,
  },
  {
    title: "Title",
    dataIndex: "title",
    width: 200,
  },
  {
    title: "Start Date",
    dataIndex: "start_date",
    class: "font-semibold text-muted",
  },
  {
    title: "End Date",
    dataIndex: "end_date",
    class: "font-semibold text-muted",
  },
  {
    title: "Status",
    dataIndex: "status",
    class: "font-semibold text-muted",
  },
  {
    title: "Action",
    scopedSlots: { customRender: "updateBtn" },
    width: 200,
  },
];

export default {
  name: "Flows",
  data() {
    return {
      config: {
        Authorization: "Bearer " + this.$store.state.authUser.token,
      },
      columnData: COLUMN_DATA,
      flows: [],
      projectHeaderBtns: "all",
      currentPage: 1,
      pagination: 0,
      confirmLoading: false,
    };
  },
  created() {
    this.getFlowList();
  },
  methods: {
    onPageChange() {
      this.getFlowList();
    },
    getFlowList() {
      this.confirmLoading = true;
      let data = {
        page: this.currentPage,
      };
      sendRequest("get", "admin/flows", data, this.config)
        .then((response) => {
          this.confirmLoading = false;
          if (response.success) {
            let data = response.data.flows.data;
            this.flows = data.map(function (content, label) {
              return {
                key: label + 1,
                id: content.id,
                title: content.title,
                start_date: content.start_date,
                end_date: content.end_date,
                created_at: content.created_at,
                features: content.features.map((feature) => {
                  return feature.name;
                }),
                status: content.status ? "Active" : "Inactive",
                details: content,
              };
            });
            this.pagination = response.data.flows.total;
            this.currentPage = response.data.flows.current_page;
            if (this.responseMessage > "") {
              message.success(this.responseMessage);
            }
          } else {
            if (response.status_code === 401) {
              this.$store.dispatch("logout");
              this.$router.push("/login");
            }
          }
        })
        .catch(() => {
          this.confirmLoading = false;
        })
        .finally(() => {
          this.confirmLoading = false;
        });
    },
    updateFlow(flow_id) {
      this.$router.push({
        path: "/flow-update",
        query: { id: flow_id },
      });
    },
    async cloneItem(flow_id) {
      await Swal.fire({
        title: "Are you sure to clone?",
        text: "You won't be able to revert this!",
        icon: "warning",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        showCancelButton: true,
        confirmButtonText: "Yes, clone it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      }).then((confirmation) => {
        if (!confirmation.isConfirmed) return;
      });
      this.confirmLoading = true;
      const data = [];
      sendRequest("get", "admin/flow/clone/" + flow_id, data, this.config).then(
        (res) => {
          this.confirmLoading = false;
          window.location.reload();
        }
      );
    },
  },

  computed: {
    flowList: function () {
      return this.flows;
    },
    responseMessage() {
      const message = this.$store.state.message;
      this.$store.commit("resetMessage");
      return message;
    },
  },
};
</script>
